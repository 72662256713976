<app-header></app-header>
<div class="sub-heading-font col-md-10 text-center">Welcome to FileCapture</div>
<div class="container">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
        <p style="color: white">Please do not Cancel or Refresh page</p>
    </ngx-spinner>
    <div class="row">
        <div class="input-div col-md-12">
            <form [formGroup]="referenceForm">
                <input type="text" name="referenceNumber" maxlength="60" formControlName="referenceNumber" class="input" id="Reference-id" placeholder="Please Enter a Reference ID">
                <button class="btn-enter" id="dashboardButton" tooltip="You must enter a valid Reference ID before proceeding" type="submit" (click)="Next()" [disabled]="referenceForm.invalid">Enter</button>
                <button class="btn-clear" id="dashboardButton" type="button" (click)="clear()" [disabled]="referenceForm.invalid">Clear</button>
            </form>
        </div>
    </div>
    <div class="col-md-12" style="padding-left: 26%;" *ngIf="error">You must enter a valid Reference ID before proceeding</div>
</div>
<app-footer></app-footer>

