<app-header></app-header>
<div class="col-md-4 offset-md-1">
  <div class="row">
    <span class="text-white ref">Reference ID</span>
  </div>
  <div class="row">
    <span class="ref-id-no">{{service.referenceFileDetails.referenceId}}</span>
  </div>

  <form [formGroup]="emailForm" (ngSubmit)="next()">
 
    <div class="form-group mt-2 mb-2">
      <label class="text-white">An Email Verification Code has been emailed to</label>
      <span>
        <input type="text" class="form-control" id="validate-email" placeholder="Email" formControlName="validateEmail" name="validateEmail">
      </span>
      <label class="text-white">Enter the Code Here:</label>
      <span>
        <input type="text" class="form-control" id="email-code" (keypress)="enableSubmit()" (click)="enableSubmit()" placeholder="Email Code" formControlName="emailCode" name="emailCode" maxlength="6">
      </span>
    </div>
  </form>
</div>

<div class="col-md-5 offset-md-1">
  <div class="col container--flex">
    <button type="button" class="btn btn-warn me-2"  id="dashboardButton" (click)="close()">Close</button>
    <button class="btn btn-success me-2" type="submit" [disabled]="isValidCodeProgress" (click)="validateCode()">Click Here To Verify The Code Entered</button>
    <span class="spinner-border text-light me-2" role="status" [style.display]="isVisible ? 'block' : 'none'"></span>
    <span class="text-warning me-2" [style.display]="invalid ? 'block' : 'none'">Entered Code is invalid</span> 
    <button class="btn btn-success me-2" type="submit" [disabled]="timerSeconds != 0" (click)="resetTimer()">ReSend Code</button>
    <span class="text-white">{{ timerMinutes }}:{{ timerSeconds | number: '2.0-0' }}</span>
  </div>
</div>

<app-footer></app-footer>
