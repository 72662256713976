<app-header></app-header>
<div class="col-md-3 offset-md-1">
  <div class="row">
    <span class="text-white ref">Reference ID</span>
  </div>
  <div class="row">
    <span class="ref-id-no">{{service.referenceFileDetails.referenceId}}</span>
  </div>
</div>
<form (ngSubmit)="next()">
  <div class="row">
    <div class="col-md-2 offset-md-1 mt-5">
      <a class="btn btn-info w-40" *ngIf="service.pageIndex>0" (click)="service.navigateTo('reference')">Contact Information</a>
    </div>
    <div class="col-md-2 mt-5">
      <a class="btn btn-info w-40" *ngIf="service.pageIndex>2" (click)="service.navigateTo('file-upload')">File Upload</a>
    </div>
    <div class="col-md-2 mt-5 ">
      <a class="btn btn-info w-40" *ngIf="service.pageIndex>3" (click)="service.navigateTo('review-files')">Review Files</a>
    </div>
    <div class="col-md-2 mt-5 ">
      <a class="btn btn-info w-40" *ngIf="service.pageIndex>4" (click)="service.navigateTo('initiate-download')">Initiate Download</a>
    </div>
  </div>
  <div class="row mt-5 mb-3">
    <div class="text-center text-white">
      <h4>Please Confirm the number of Files Uploaded is correct before Submission!</h4>
    </div>
  </div>
  <div class="row">
    <div class="text-white text-center col mb-5">
      <span class="border-1">{{service.referenceFileDetails.files.length}} Files have been Uploaded</span>
    </div>
  </div>
  <div class="row">
    <div class="col text-center mb-3 ">
      <span class="text-white">If you are Finished and have verified the number of files is correct, click Submit Documents. Once
        files have been successfully uploaded, you will receive a Confirmation email. If a problem occurs
        during the file transfer, you will be notified and asked to re-submit any files that failed.</span>
    </div>
  </div>
  <div class="row">
    <div class="text-white text-center mb-3">
      <span>You cannot click Back once you have selected Submit. This is the final step.</span>
    </div>
  </div>
  <div class="row">
    <div class="g-recaptcha" data-sitekey="your_site_key"></div>
  </div>
  <div class="container">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
      <p style="color: white">Please do not Cancel or Refresh page</p>
    </ngx-spinner>
    <div class="row justify-content-center">
      <button type="button" class="btn btn-war pl-3 pr-3 mr-3" (click)="back()">Back</button>
      <button type="submit" class=" btn btn-success">Submit Documents</button>
    </div>
  </div>
</form>
<app-footer></app-footer>
