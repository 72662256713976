<div class="row justify-content-start align-items-center g-2">
    <div class="col col-md-2 offset-1">
        <img src="../../assets/images/logo.png" alt="" class="img img-fluid">
    </div>
</div>

<div class="row">   
    <div class="col col-md-4 offset-1 mb-3">
        <h1 class="main-heading-font"><span class="color">FileCapture</span></h1>
    </div>
</div>
