import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FileServiceService } from '../service/file-service.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {

  date: Date | undefined;

  constructor(private route: Router, public service: FileServiceService) { }

  ngOnInit(): void {
    this.date = new Date();
  }

  exit() {
    this.service.reset();
    this.service.clearCache();    
    this.route.navigate(['']);
  }

}
