<app-header></app-header>

<div class="col-md-3 offset-md-1">
    <div class="row">
        <span class="text-white ref">Reference ID</span>
    </div>
    <div class="row">
        <span class="ref-id-no">{{service.referenceFileDetails.referenceId}}</span>
    </div>
</div>

<form (ngSubmit)="next()">
    <div class="row row-cols-md-5 g-2 g-md-3">
        <div class="col ms-md-4">
          <div class="p-3 border bg-light">
            <a class="btn btn-info w-100" *ngIf="service.pageIndex>0" (click)="service.navigateTo('reference')">Contact Information</a>
          </div>
        </div>
        <div class="col">
          <div class="p-3 border bg-light">
            <a class="btn btn-info w-100" *ngIf="service.pageIndex>2" (click)="service.navigateTo('file-upload')">File Upload</a>
          </div>
        </div>
        <div class="col">
          <div class="p-3 border bg-light">
            <a class="btn btn-info w-100" *ngIf="service.pageIndex>3" (click)="service.navigateTo('review-files')">Review Files</a>
          </div>
        </div>
        <div class="col">
          <div class="p-3 border bg-light">
            <a class="btn btn-info w-100" *ngIf="service.pageIndex>4" (click)="service.navigateTo('initiate-download')">Initiate Download</a>
          </div>
        </div>
      </div>

    <div class="row mb-5">
        <div class="col-md-5 offset-md-1">
            <div class="overflow-auto">
                <table class="table text-white">
                    <thead class="bg-dark">
                        <tr>
                            <th class="col-md-3" scope="col"></th>
                            <th class="col-md-3" scope="col">File Name</th>
                            <th class="col-md-3" scope="col">File Size</th>
                            <th class="col-md-3" scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of files; let i=index;">
                            <th scope="row">
                                <div class="col-md-3">
                                    <button type="button" class="btn btn-warn" (click)="removeFile(i)">Remove File</button>
                                </div>
                            </th>
                            <td class="col-md-3 text-white-sub">{{item.fileName}}</td>
                            <td class="col-md-3 text-white-sub">{{item.fileSize | fileSize}}</td>
                            <td class="col-md-3 text-white-sub">
                                <div [attr.id]="item.attachmentKey + '_0'" [style.display]="item.isVisible == (item.attachmentKey + '_0') ? 'block' : 'none'" class="text-warning"><i class="fa fa-spinner fa-spin"></i></div>
                                <div [attr.id]="item.attachmentKey + '_1'" [style.display]="item.isVisible == (item.attachmentKey + '_1') ? 'block' : 'none'" class="text-success"><i class="fa fa-check"></i></div>
                                <div [attr.id]="item.attachmentKey + '_99'" [style.display]="item.isVisible == (item.attachmentKey + '_99') ? 'block' : 'none'" class="text-danger"><i class="fa fa-xmark"></i></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <table class="table text-white">
                <tbody>
                    <tr>
                        <th scope="row">Total</th>
                        <td>{{totalFilesSize | fileSize}}</td>
                        <td>
                            <div class="col-md-2">
                                <button type="button" class="btn btn-success" [disabled]="isDisabled" (click)="addNewFile()">Add Additional Files</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-5">
            <div class="row">
                <span class="text-white">Upload Review:</span>
            </div>
            <div>
                <span class="text-white-sub">❑ Confirm all requested files have been uploaded with individual file names.</span>
            </div>
            <div>
                <span class="text-white-sub">❑ To Add Files not previously uploaded, select Add Additional Files.</span>
            </div>
            <div>
                <span class="text-white-sub">❑ To Remove individual files, select Remove File.</span>
            </div>
            <div>
                <span class="text-white-sub">❑ Once you have confirmed all files are uploaded, click Next to Initiate Download.</span>
            </div>
            <div class="mt-5">
                <span class="text-white">Files are not submitted until you reach the final submission step.</span>
            </div>
        </div>
    </div>

    <div class="row mt-3 mb-5">
        <div class="col-md-1 offset-md-1">
            <button type="button" class="btn btn-war" (click)="back()">Back</button>
        </div>
        <div class="col">
            <button type="submit" class="btn btn-success" [disabled]="isDisabled">Next to Initiate Download</button>
        </div>
    </div>
</form>
<app-footer></app-footer>
