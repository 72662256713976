import { AfterContentInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { count, from, map, of, reduce } from 'rxjs';
import { FileServiceService } from '../service/file-service.service';
import { FileCaptureService } from 'app/service/file-capture.service';

@Component({
  selector: 'app-review-files',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './review-files.component.html',
  styleUrls: ['./review-files.component.css']
})
export class ReviewFilesComponent implements OnInit {
  files: any;
  totalFilesSize: number = 0;
  isDisabled: boolean;

  constructor(
    private route: Router,
    public service: FileServiceService,
    private fcservice: FileCaptureService,
    private cdr: ChangeDetectorRef
  ) {
    this.service.pageIndex = 4;
  }

  ngOnInit(): void {
    if (this.service.getCache('files')) {
      this.service.referenceFileDetails.files = this.service.getCache('files');
      this.service.referenceFileDetails.files.forEach((item: any) => {
        if (!item.isVisible) {
          item.isVisible = item.attachmentKey + "_0";
          this.isDisabled = true;
        }
      });
      this.files = this.service.referenceFileDetails.files;
      this.isDisabled = this.files.filter(x => x.isVisible == (x.attachmentKey + "_0")).length > 0;
      from(this.service.referenceFileDetails.files.map((x: any) => x.fileSize))
        .pipe(reduce((a, b) => a + b, 0))
        .subscribe(x => this.totalFilesSize = x);
      this.service.setCacheForitems([{ id: "files", value: this.files }]);
      this.reviewAndScan();
      const attachment = {
        referenceId: '',
        attachmentKeyList: []
      };
      attachment.referenceId = this.service.getCache('referenceId');
      attachment.attachmentKeyList = this.files.map(x => x.attachmentKey);
      this.fcservice.pollStatus(5000, attachment).subscribe(status => {
        if (status) {
          console.log('Condition met, stopping polling.');
          this.files.forEach((item) => {
            if (status.filter(y => y.id == item.attachmentKey).length) {
              item.isVisible = (item.attachmentKey + "_" + status.filter(y => y.id == item.attachmentKey)[0].VirusScanStatus);
              this.isDisabled = this.files.filter(x => x.isVisible == (x.attachmentKey + "_0")).length > 0;
              this.cdr.detectChanges();
            }
          });
        } else {
          console.log('Condition not met, polling continues.');
        }
      });
    }
  }

  reviewAndScan() {
    setTimeout(() => {
      const attachment = {
        referenceId: '',
        attachmentKeyList: []
      };
      attachment.referenceId = this.service.getCache('referenceId');
      attachment.attachmentKeyList = this.files.map(x => x.attachmentKey);
      if (attachment.attachmentKeyList.length > 0) {
        this.fcservice.ReviewAndScanAttachmnents(attachment).subscribe(
          (result: boolean) => {
            console.log(result);
          },
          (error: any) => {
            console.log(error);
          }
        );
      }
    }, 3000);
  }

  next() {
    this.route.navigate(['initiate-download']);
  }

  removeFile(index: number) {
    this.totalFilesSize = this.totalFilesSize - this.files[index].fileSize;
    this.files.splice(index, 1);
    this.service.setCacheForitems([{ id: "files", value: this.files }]);
  }

  addNewFile() {
    this.route.navigate(['file-upload']);
  }

  back() {
    this.route.navigate(['file-upload']);
  }
}