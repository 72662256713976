<app-header></app-header>
<div class="col-md-3 offset-md-1">
  <div class="row">
    <span class="text-white ref">Reference ID</span>
  </div>
  <div class="row">
    <span class="ref-id-no">{{service.referenceFileDetails.referenceId}}</span>
  </div>
</div>
<div class="col-md-12 col-lg-12 col-sm-12"> 
  <form (ngSubmit)="next()">
    <div class="row row-cols-md-5 g-2 g-md-3">
      <div class="col ms-md-4">
        <div class="p-3 border bg-light">
          <a class="btn btn-info w-100" *ngIf="service.pageIndex>0" (click)="service.navigateTo('reference')">Contact Information</a>
        </div>
      </div>
      <div class="col">
        <div class="p-3 border bg-light">
          <a class="btn btn-info w-100" *ngIf="service.pageIndex>2" (click)="service.navigateTo('file-upload')">File Upload</a>
        </div>
      </div>
      <div class="col">
        <div class="p-3 border bg-light">
          <a class="btn btn-info w-100" *ngIf="service.pageIndex>3" (click)="service.navigateTo('review-files')">Review Files</a>
        </div>
      </div>
      <div class="col">
        <div class="p-3 border bg-light">
          <a class="btn btn-info w-100" *ngIf="service.pageIndex>4" (click)="service.navigateTo('initiate-download')">Initiate Download</a>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="showError">
      <div class="col-md-6 offset-md-1 mt-5">
        <div class="alert alert-danger alert-dismissible fade show" role="alert">
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="showError=!showError"></button>
          <strong>Error </strong>{{errorMsg}} 
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col col-md-6 file-uploader-div offset-md-3 ml-2 dropzone" fileDragDrop (filesChangeEmiter)="onFileChange($event)">
        <input type="file" name="file" id="file" (change)="onFileChange($event.target)" [accept]="acceptedFiles" multiple>
        <label for="file"><span class="textLink">Choose a File or Drag Files to Upload</span></label>
      </div>
      <div class="col col-md-4" *ngIf="files.length > 0">
        <div class="overflow-auto">
          <table class="table text-white">
            <thead class="bg-dark">
              <th scope="col">Files</th>
            </thead>
            <tbody>
              <tr *ngFor="let item of files">
                <td class="text-white-sub">{{item.fileName ?? item.name}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="row mt-3 mb-5">
      <div class="col-md-1 offset-md-1">
        <button type="button" class="btn btn-warn" (click)="back()">Back</button>
      </div>
      <div class="col col-md-5">
        <button type="submit" class="btn btn-success" [disabled]="isDisabled">Next to Review Files</button>
        <span class="spinner-border text-light" role="status" [style.display]="isDisabled ? 'block' : 'none'"></span>
        <span [style.display]="isDisabled ? 'block' : 'none'" class="text-warning">Files are being uploaded</span>
      </div>
    </div>
  </form>
</div>
<div class="row">
  <div class="col-md-6 offset-md-1">
    <span class="text-white">Upload Instructions:</span>
  </div>
  <div class="col-md-11 offset-md-1">
    <span class="text-white-sub">1. Click in the box to upload files or drag and drop files directly into the box.</span>
  </div>
  <div class="col-md-11 offset-md-1">
    <span class="text-white-sub">2. Each file should be uploaded individually, separated by Patient Name or as directed in the request letter</span>
  </div>
  <div class="col-md-11 offset-md-1">
    <span class="text-white-sub">3. Acceptable file types include: doc, docx, dcm, pdf, xls, xlsx, jpeg, jpg, bmp, png, tif, tiff, wav, mp3, txt, and text/plain.</span>
  </div>
  <div class="col-md-11 offset-md-1">
    <span class="text-white-sub">4. If the file exceeds 1.5GB, please split the files into multiple files.</span>
  </div>
  <div class="col-md-11 offset-md-1">
    <span class="text-white-sub">Once all files have been uploaded, click Next to Review Files. Files are not submitted until you reach the final
      submission step.</span>
  </div>
</div>
<!-- Footer  -->
<div class="mt-5">        
  <span class="text-white-lite">For questions or issues with this website, please contact servicedesk@gainwelltechnologies.com. If you have any questions regarding specific information requested, please reference the contact information contained in the request letter</span>
<div class="row">
  <div class="text-white-s ml-5">
      <a href="https://www.gainwelltechnologies.com/site-terms/" target="_blank" class="text-white-s">Terms of Use</a> | 
      <a href="https://www.gainwelltechnologies.com/privacy/" target="_blank" class="text-white-s">Privacy Policy</a> | 
      <a href="https://www.gainwelltechnologies.com/cookie-policy/" target="_blank" class="text-white-s">Cookie Policy</a> |
  </div>
</div>
</div>