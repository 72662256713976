<div class="row justify-content-start align-items-center g-2">
    <div class="col col-md-3 offset-2" >
        <img src="../../assets/images/logo.png" alt="" class="img img-fluid">
    </div>
  </div>  
<div class="row">
<div class=" col-md-3 offset-md-2 ">
    <span class="text-white" class="color">FileCapture</span> 
</div>
<div class="col-md-4">
    <span class="text-white pl-5 ">Thank You !</span>
</div>
</div>
<div class="row">
    <div class="col-md-12 text-center">
        <i class="fa-solid fa-check"></i>
    </div>
</div>
<div class="row">
    <div class="col-md-4 text-white-lite offset-md-4 pad solid pt-3 pb-3 mb-5">
        <label class="text-white-ulte">Reference ID: </label> <span class="text-white-sub"> {{service.referenceFileDetails.referenceId}}</span>
        <label class="text-white-ulte">Date Submitted: </label> <span class="text-white-sub"> {{date | date}}</span>
        <label class="text-white-ulte">Number of Files Submitted: </label> <span class="text-white-sub"> {{service.referenceFileDetails.files.length}}</span>
    </div>
</div>
<div class="row">
    <div class="col-md-10 offset-md-1 text-white-sub mb-3">
        You will receive a Confirmation Email once all files are successfully transferred. If at any time you wish to check the
status of the files, or need to upload additional files, please go back to the FileCapture home page and use the
Reference ID found in the request letter.
    </div>
</div>
<div class="row justify-content-center">
    <button type="button" class="btn btn-success pl-3 pr-3 mr-3" (click)="exit()">Exit</button>
</div>
