<div class="footer">
    <div class="row">
        <div class="col-sm-10 mt-3 mb-1">
            <span class="text-white-lite">For questions or issues with this website, please contact servicedesk@gainwelltechnologies.com. If you have any questions regarding specific information requested, please reference the contact information contained in the request letter</span>
        </div>
    </div>
    <div class="row">
        <div class="text-white-s ml-5">
            <a href="https://www.gainwelltechnologies.com/site-terms/" target="_blank" class="text-white-s">Terms of Use</a> | 
            <a href="https://www.gainwelltechnologies.com/privacy/" target="_blank" class="text-white-s">Privacy Policy</a> | 
            <a href="https://www.gainwelltechnologies.com/cookie-policy/" target="_blank" class="text-white-s">Cookie Policy</a> |
        </div>
    </div>
</div>
